import React, { useContext } from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { UserContext } from "contexts/UserProvider";
import ReadMe from "components/ReadMe/ReadMe";
import SyndicationGroup from "components/Dashboard/SyndicationGroup/SyndicationGroup";
import Nominate from "components/Dashboard/Nominate/Nominate";
import Help from "components/Dashboard/Help/Help";
import Feedback from "components/App/Feedback/Feedback";
import { WhatsNew } from "../WhatsNew/WhatsNew";
import APCatalyst from "components/Dashboard/APCatalyst/APCatalyst";

const Header = ({ title }) => {
  const user = useContext(UserContext);

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title || ""}</title>
      </Helmet>

      <Navbar
        variant="light"
        expand="lg"
        fixed="top"
        className="border-bottom MainHeader"
        bg="primary"
      >
        <Container fluid>
          <Navbar.Brand className="text-white" as={Link} to="/">
            ACA Syndication <sup>BETA</sup>
          </Navbar.Brand>

          <Navbar.Toggle />

          <Nav className="ms-auto">
            {user.role === "investor" && (
              <>
                <SyndicationGroup />
              </>
            )}
          </Nav>

          <Navbar.Collapse>
            <Nav className="ms-auto">
              <Feedback />
              {user.role === "investor" && (
                <>
                  <Help />
                  <ReadMe />
                  <Nominate />
                  <APCatalyst />
                  <WhatsNew />
                </>
              )}

              <NavDropdown
                align="end"
                title={`Hi, ${
                  user?.role === "investor"
                    ? user.name || user.firstName + user.lastName
                    : `${user.firstName} ${user.lastName}`
                }`}
              >
                <NavDropdown.Item disabled>{user.email}</NavDropdown.Item>

                <NavDropdown.Divider />

                <NavDropdown.Item as={Link} to="/logout">
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
};

Header.propTypes = {
  title: PropTypes.string,
};

export default Header;
