import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./NoMatch.css";

/**
 * 404: No match route
 */
const NoMatch = ({ location }) => (
  <Col xs={12} className="not-found-container">
    <img className="" alt="Logo" src="/etiquetteLogo.png" width={350} />
    <h1 className="mt-3 mb-3 not-found ">404</h1>
    <p>Sorry, the page you visited does not exist.</p>
    <Link to="/">
      <button className="btn btn-primary">Go To Homepage</button>
    </Link>
  </Col>
);

export default NoMatch;
