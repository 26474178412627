import { useContext, useState } from "react";
import {
  Modal,
  Alert,
  Spinner,
  Button,
  Form,
  Badge,
  Nav,
} from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { instance, MakeCancelable, config } from "utils/DeApi";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import axios from "axios";
import { UserContext } from "contexts/UserProvider";
import { formatEmailArray } from "utils/StringUtils";

const APCatalyst = () => {
  const [showNominate, setShowNominate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [showMsg, setShowMsg] = useState("");
  const user = useContext(UserContext);

  const handleCloseNominate = () => {
    setShowNominate(false);
    setShowMsg("");
    setError();
  };
  const handleShowNominate = () => setShowNominate(true);

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Must be a valid email address *")
      .required("Email is required *"),
    emails: yup
      .array()
      .of(yup.string().email("Must be a valid email address *"))
      .required("At least one email is required"),
  });

  const initialValues = {
    emails: [],
    email: "",
  };

  const checkEmails = ({ email, emails }) => {
    const allSame =
      emails?.length > 1 &&
      emails.every(
        (arrayEmail) =>
          arrayEmail !== "" && emails[0] !== "" && arrayEmail === emails[0]
      );
    const inArray = emails.includes(email);
    const duplicateIndices = [];
    const emailSet = new Set();

    for (let i = 0; i < emails.length; i++) {
      if (emailSet.has(emails[i])) {
        duplicateIndices.push(i);
      } else {
        emailSet.add(emails[i]);
      }
    }

    if (inArray) {
      duplicateIndices.push(emails.indexOf(email));
    }

    return [allSame || inArray, duplicateIndices];
  };

  const nominateEmail = ({ emails, email }) => {
    setIsLoading(true);
    setError();
    setShowMsg("");
    const nominateEmails = [email, ...emails].filter(
      (email) => email.trim() !== ""
    );

    const tempInstance = axios.create({
      baseURL: process.env.REACT_APP_APC_DE_ENDPOINT,
      headers: instance.defaults.headers.common,
      withCredentials: true,
    });

    const nominateEmail = MakeCancelable(
      tempInstance.post(`nominations`, {
        email: nominateEmails,
        nominator_email: user?.email,
        token: "AP_2244",
        ...config,
      })
    );

    nominateEmail.promise
      .then(({ error, data: { data: emails } }) => {
        setError();
        if (error) {
          setError({
            data: {
              error: {
                message: error,
              },
            },
          });
        } else {
          setShowMsg(
            `${formatEmailArray(emails)} ${
              emails.length > 1 ? "have" : "has"
            } been successfully nominated for AP Catalyst.`
          );
        }
      })
      .catch((error) => {
        if (!error.isCanceled) {
          if (error?.response) {
            setError(error?.response?.data?.message);
          } else {
            setError(error);
          }
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Nav.Link onClick={handleShowNominate}>
        AP Catalyst{" "}
        <sup className="text-danger">
          <Badge bg="danger">NEW</Badge>
        </sup>
      </Nav.Link>

      <Modal
        show={showNominate}
        backdrop={"static"}
        onHide={handleCloseNominate}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Nomination Criteria</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={(values) => nominateEmail(values)}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
            setFieldValue,
          }) => (
            <>
              <Modal.Body>
                <div>
                  <p>
                    <a
                      href="https://www.apcatalyst.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      AP Catalyst
                    </a>{" "}
                    is a pioneering program that connects exit-ready,
                    angel-backed companies with acquirers actively seeking
                    acquisitions. This pilot program features a dynamic "speed
                    dating" format, allowing companies to showcase their
                    potential to multiple acquirers in rapid succession. You may
                    nominate any of your portfolio companies that meet the
                    criteria below.
                  </p>
                  <p className="text-danger">
                    Deadline: CEOs must accept your nomination and complete
                    their application by February 10th at 11:59 PM US Eastern
                    time.
                  </p>
                  <p>
                    <strong>Nomination Criteria:</strong> You must be an
                    investor in the company and it must be generating at least
                    $1 million in revenue, seeking growth equity, and desiring
                    some liquidity for themselves & you.
                  </p>
                  <strong>How to Nominate:</strong>
                  <ul>
                    <li>Enter the CEO’s email address.</li>
                    <li>
                      To nominate multiple companies, click the “+” button to
                      add additional email fields.
                    </li>
                    <li>
                      Press “SEND EMAIL” to initiate the nomination process.
                    </li>
                  </ul>
                  <strong>Next Steps:</strong>
                  <ul>
                    <li>
                      We will send detailed instructions to the CEO and copy you
                      on the email.
                    </li>
                    <li>The CEO must complete their application promptly.</li>
                    <li>
                      Acquirers will review nominees and select which ones they
                      wish to see at the online private company showcase on
                      February 26th at noon. If one or more of your nominees is
                      selected, you will also be invited to attend the showcase.
                    </li>
                  </ul>
                  <Form.Group controlId="email">
                    <Form.Label>Emails</Form.Label>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <Form.Control
                          name="email"
                          type="text"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={errors.email && touched.email}
                          placeholder="Enter email"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors?.email}
                        </Form.Control.Feedback>
                      </div>

                      <button
                        onClick={() => {
                          setShowMsg("");
                          setFieldValue("emails", [...values.emails, ""]);
                        }}
                        className="icon-btn rounded-circle bg-transparent border border-primary cursor-pointer mx-2 my-1"
                      >
                        <i className="icon-add material-icons text-primary">
                          add
                        </i>
                      </button>
                    </div>
                  </Form.Group>

                  <div className="emails-container my-2">
                    {values?.emails?.map((email, index) => (
                      <Form.Group key={index} controlId="emails">
                        <div className="d-flex my-2">
                          <div className="flex-grow-1">
                            <Form.Control
                              type="text"
                              name={`emails[${index}]`}
                              placeholder="Enter email"
                              value={values?.emails?.[index]}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={
                                errors?.emails?.[index] &&
                                touched?.emails?.[index]
                              }
                            />
                            {checkEmails(values)?.[0] &&
                            values?.email &&
                            checkEmails(values)?.[1]?.includes(index) ? (
                              <Form.Text type="invalid" className="text-danger">
                                This email is already added!
                              </Form.Text>
                            ) : (
                              <Form.Control.Feedback type="invalid">
                                {errors?.emails?.[index]}
                              </Form.Control.Feedback>
                            )}
                          </div>
                          <button
                            className="icon-btn rounded-circle bg-transparent border border-danger my-1 mx-2 cursor-pointer"
                            onClick={() => {
                              const updatedEmails = [...values.emails];
                              updatedEmails.splice(index, 1);
                              setFieldValue("emails", updatedEmails);
                              setShowMsg("");
                            }}
                          >
                            <i className="icon-del material-icons text-danger">
                              delete
                            </i>
                          </button>
                        </div>
                      </Form.Group>
                    ))}
                  </div>

                  {showMsg && (
                    <Alert variant="success" className="mt-3">
                      <h4>Success!</h4>
                      <p>{showMsg}</p>
                    </Alert>
                  )}
                  {error && <ErrorHandler message={error} />}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button size="sm" variant="link" onClick={handleCloseNominate}>
                  Close
                </Button>
                <Button
                  size="sm"
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={
                    !values?.email ||
                    !isValid ||
                    checkEmails(values)?.[0] ||
                    isLoading
                  }
                >
                  Send Email{"  "}
                  {isLoading && (
                    <Spinner
                      animation="border"
                      variant="white"
                      size="sm"
                      className="ms-3"
                    />
                  )}
                  {error && (
                    <span className="material-icons-outlined md-18 text-danger">
                      error
                    </span>
                  )}
                </Button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default APCatalyst;
